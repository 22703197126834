/**
 * Cheap way of pluralizing a word. Can include the count with the word.
 * ```pluralize('primary outcome', 2, { includeCount: true }) === '2 primary outcomes'```
 * @param word English word to pluralize
 * @param count Number of items to determine plurality
 * @param options.includeCount Prefix the word with the count
 */
export default function pluralize(
  word: string,
  count: number,
  { includeCount }: { includeCount?: boolean } = {},
): string {
  const pluralWord = count === 1 ? makeSingular(word) : makePlural(word)

  if (includeCount) {
    return `${count} ${pluralWord}`
  }

  return pluralWord
}

// Pluralization and Singularization rules stolen from https://github.com/plurals/pluralize/blob/master/pluralize.js
const pluralizationRules: [string | RegExp, string][] = [
  [/s?$/i, 's'],
  [/[^\u0000-\u007F]$/i, '$0'],
  [/([^aeiou]ese)$/i, '$1'],
  [/(ax|test)is$/i, '$1es'],
  [/(alias|[^aou]us|t[lm]as|gas|ris)$/i, '$1es'],
  [/(e[mn]u)s?$/i, '$1s'],
  [/([^l]ias|[aeiou]las|[ejzr]as|[iu]am)$/i, '$1'],
  [
    /(alumn|syllab|vir|radi|nucle|fung|cact|stimul|termin|bacill|foc|uter|loc|strat)(?:us|i)$/i,
    '$1i',
  ],
  [/(alumn|alg|vertebr)(?:a|ae)$/i, '$1ae'],
  [/(seraph|cherub)(?:im)?$/i, '$1im'],
  [/(her|at|gr)o$/i, '$1oes'],
  [
    /(agend|addend|millenni|dat|extrem|bacteri|desiderat|strat|candelabr|errat|ov|symposi|curricul|automat|quor)(?:a|um)$/i,
    '$1a',
  ],
  [
    /(apheli|hyperbat|periheli|asyndet|noumen|phenomen|criteri|organ|prolegomen|hedr|automat)(?:a|on)$/i,
    '$1a',
  ],
  [/sis$/i, 'ses'],
  [/(?:(kni|wi|li)fe|(ar|l|ea|eo|oa|hoo)f)$/i, '$1$2ves'],
  [/([^aeiouy]|qu)y$/i, '$1ies'],
  [/([^ch][ieo][ln])ey$/i, '$1ies'],
  [/(x|ch|ss|sh|zz)$/i, '$1es'],
  [/(matr|cod|mur|sil|vert|ind|append)(?:ix|ex)$/i, '$1ices'],
  [/\b((?:tit)?m|l)(?:ice|ouse)$/i, '$1ice'],
  [/(pe)(?:rson|ople)$/i, '$1ople'],
  [/(child)(?:ren)?$/i, '$1ren'],
  [/eaux$/i, '$0'],
  [/m[ae]n$/i, 'men'],
  ['thou', 'you'],
]

const singularizationRules: [string | RegExp, string][] = [
  [/s$/i, ''],
  [/(ss)$/i, '$1'],
  [/(wi|kni|(?:after|half|high|low|mid|non|night|[^\w]|^)li)ves$/i, '$1fe'],
  [/(ar|(?:wo|[ae])l|[eo][ao])ves$/i, '$1f'],
  [/ies$/i, 'y'],
  [/(dg|ss|ois|lk|ok|wn|mb|th|ch|ec|oal|is|ck|ix|sser|ts|wb)ies$/i, '$1ie'],
  [
    /\b(l|(?:neck|cross|hog|aun)?t|coll|faer|food|gen|goon|group|hipp|junk|vegg|(?:pork)?p|charl|calor|cut)ies$/i,
    '$1ie',
  ],
  [/\b(mon|smil)ies$/i, '$1ey'],
  [/\b((?:tit)?m|l)ice$/i, '$1ouse'],
  [/(seraph|cherub)im$/i, '$1'],
  [
    /(x|ch|ss|sh|zz|tto|go|cho|alias|[^aou]us|t[lm]as|gas|(?:her|at|gr)o|[aeiou]ris)(?:es)?$/i,
    '$1',
  ],
  [
    /(analy|diagno|parenthe|progno|synop|the|empha|cri|ne)(?:sis|ses)$/i,
    '$1sis',
  ],
  [/(movie|twelve|abuse|e[mn]u)s$/i, '$1'],
  [/(test)(?:is|es)$/i, '$1is'],
  [
    /(alumn|syllab|vir|radi|nucle|fung|cact|stimul|termin|bacill|foc|uter|loc|strat)(?:us|i)$/i,
    '$1us',
  ],
  [
    /(agend|addend|millenni|dat|extrem|bacteri|desiderat|strat|candelabr|errat|ov|symposi|curricul|quor)a$/i,
    '$1um',
  ],
  [
    /(apheli|hyperbat|periheli|asyndet|noumen|phenomen|criteri|organ|prolegomen|hedr|automat)a$/i,
    '$1on',
  ],
  [/(alumn|alg|vertebr)ae$/i, '$1a'],
  [/(cod|mur|sil|vert|ind)ices$/i, '$1ex'],
  [/(matr|append)ices$/i, '$1ix'],
  [/(pe)(rson|ople)$/i, '$1rson'],
  [/(child)ren$/i, '$1'],
  [/(eau)x?$/i, '$1'],
  [/men$/i, 'man'],
]

function sanitizeRule(rule: string | RegExp): RegExp {
  if (typeof rule === 'string') {
    return new RegExp('^' + rule + '$', 'i')
  }

  return rule
}

// Interpolate the rule to actually do the string replacement
function interpolate(str: string, args: IArguments): string {
  return str.replace(/\$(\d{1,2})/g, function (_match, index) {
    return args[index] || ''
  })
}

// Find the match and augment the matched content
function replace(word: string, rule: [string | RegExp, string]): string {
  return word.replace(rule[0]!, (_match, _index) => {
    return interpolate(rule[1], arguments)
  })
}

function makePlural(word: string): string {
  // Find the first rule to match
  const usableRule = pluralizationRules.find((rule) =>
    sanitizeRule(rule[0]!).test(word),
  )

  // No matches, just return the original word
  if (!usableRule) {
    return word
  }

  // Do the pluralization given the matched rule
  return replace(word, usableRule)
}

function makeSingular(word: string): string {
  // Find the first rule to match
  const usableRule = singularizationRules.find((rule) =>
    sanitizeRule(rule[0]!).test(word),
  )

  // No matches, just return the original word
  if (!usableRule) {
    return word
  }

  // Do the singularization given the matched rule
  return replace(word, usableRule)
}
