export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type ResponseStatus = 'ok' | 'error'

export type ApiResponseData<T = undefined> =
  | ApiResponseDataSuccess<T>
  | { error: string; status?: ResponseStatus }

export type ApiResponseDataSuccess<T = undefined> = {
  data?: T
  message?: string
  status: ResponseStatus
}

/**
 * A list of available HTTP response status codes.
 * The five classes of response are defined by:
 * 1xx: Informational - request received, continuing process
 * 2xx: Success - the action was successfully received, understood, and accepted
 * 3xx: Redirection - further action must be taken in order to complete the request
 * 4xx: Client Error - the request contains bad syntax or cannot be fulfilled
 * 5xx: Server Error - the server failed to fulfill an apparently valid request
 */
export enum StatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  RATE_LIMITED = 429,
  INTERNAL_SERVER_ERROR = 555, // Standard 500 is used by FlightControl to denote an Ops level error; 555 is a proposed alternative for User Defined Resource error: https://www.ietf.org/archive/id/draft-divilly-user-defined-resource-error-00.html
  BAD_GATEWAY = 502,
}

export const MaxFileUploadSize = '50mb'
