import { isProcessClientSide } from '@lib/config/runtime'
import { isAndroid, isIos } from '@lib/tracking/isInAppBrowser'
import isProductionEnv from '@lib/utilities/isProductionEnv'
import type { UrlObject } from 'url'
import { WWW_WITHPOWER_COM } from '..'

const LOCALHOST_URL = 'localhost:3000'

// remove 'https://' from FC_URL
const DEPLOYMENT_URL = process.env.FC_URL
  ? new URL(process.env.FC_URL).hostname
  : undefined

function isHttpsEnv() {
  return DEPLOYMENT_URL ?? process.env.NODE_ENV === 'production'
}

/**
 * Fully qualified domain name or hostname for the app, www.withpower.com for `production`,
 * use DEPLOYMENT_URL for `preview` or if set, and localhost for `development` or everything else
 */
export const FQDN = fqdn()

function fqdn() {
  if (isProcessClientSide()) {
    return window.location.hostname === 'localhost'
      ? LOCALHOST_URL // add port for dev clientside links
      : window.location.hostname
  }

  if (isProductionEnv()) {
    return WWW_WITHPOWER_COM
  }

  return DEPLOYMENT_URL ?? LOCALHOST_URL
}

function isAbsoluteUrl(maybeUrl: string) {
  try {
    const url = new URL(maybeUrl)

    return Boolean(url.host)
  } catch (e: unknown) {
    return false
  }
}

/**
 * Returns a fully qualified domain name + path string, e.g.: https://www.withpower.com/clinical-trials/prostate-cancer
 */
export default function absoluteUrl(
  path: string | UrlObject,
  { forceProduction = false }: { forceProduction: boolean } = {
    forceProduction: false,
  },
) {
  if (isAbsoluteUrl(path.toString())) {
    return path.toString()
  }

  // Allow local builds to point to production avoid hydration issues by forcing production if our CURRENT_ENV is production
  if (!forceProduction && isProductionEnv()) {
    forceProduction = true
  }
  const domain = forceProduction ? WWW_WITHPOWER_COM : FQDN
  const protocol = isHttpsEnv() || forceProduction ? 'https' : 'http'
  const pathString = path.toString()
  const formattedPath = pathString.replace(/^\/+/, '')
  return `${protocol}://${domain}/${formattedPath}`
}

/**
 * A deep link is a URL that directs a user to a specific page or content within a mobile app,
 * rather than simply launching the app.
 *
 * This function generates a deep link URL based on the platform and environment.
 * If the user agent is an Android device, it generates an intent URL.
 * If the user agent is an iOS device, it generates an x-safari URL.
 *
 * @param {string} path - The path to the specific content.
 * @param {string | null} userAgent - The user agent string to determine the platform.
 * @param {Object} options - Additional options.
 * @param {boolean} options.forceProduction - Whether to force the production environment.
 * @returns {string} The generated deep link URL.
 */
export function deepLinkUrl(
  path: string,
  userAgent: string | null,
  { forceProduction = false }: { forceProduction: boolean } = {
    forceProduction: false,
  },
) {
  if (!forceProduction && isProductionEnv()) {
    forceProduction = true
  }

  const protocol = isHttpsEnv() || forceProduction ? 'https' : 'http'
  const domain = forceProduction ? WWW_WITHPOWER_COM : FQDN
  const pathString = path.replace(/^\/+|\/+$/, '') // remove leading and trailing slashes
  const fallbackAbsoluteUrl = absoluteUrl(path, { forceProduction })

  if (isAndroid(userAgent ?? '')) {
    // The intent URL also has a package name like com.android.chrome. Without it, we would open the default browser which is ideal
    return `intent://${domain}/${pathString}/#Intent;scheme=${protocol};end;`
  } else if (isIos(userAgent ?? '')) {
    // https://christiantietze.de/posts/2023/05/safari-for-mac-url-scheme/
    return `x-safari-${fallbackAbsoluteUrl}`
  } else {
    return fallbackAbsoluteUrl
  }
}
