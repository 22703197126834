import MetricsAndTracking from '@components/app/MetricsAndTracking'
import Favicon from '@components/primitives/Layout/HeaderComponents/Favicon'
import { classMerge } from '@components/utilities/classMerge'
import type { Url } from '@lib/routes'
import { type Spacing, sizeToSpacing } from '@lib/spacing'
import Head from 'next/head'
import NoIndex from './NoIndex'
import type { PageWidth } from './ResponsivePageMargin'
import { ResponsivePageMargin } from './ResponsivePageMargin'
import TitleForHead from './TitleForHead'

/**
 * Wrapper for pages that includes default meta content, tracking pixels, page margin etc.
 */
export function PageLayoutAndTracking({
  backgroundGradient,
  canonicalUrl,
  children,
  className,
  contentWidth,
  description,
  loadHotJar = true,
  loadMaze = false,
  noIndex = false,
  spacing,
  title,
  unstyled,
}: LayoutProps) {
  const realDescription =
    description ??
    'Discover cutting-edge therapies by enrolling in clinical trials. Power helps patients everywhere take control of their care.'

  return (
    <>
      <Head>
        <meta content='width=device-width, initial-scale=1.0' name='viewport' />
        <meta content={realDescription} name='description' />
        {canonicalUrl && (
          <link href={canonicalUrl.toString()} rel='canonical' />
        )}
      </Head>
      <Favicon inSSP={false} />
      {noIndex && <NoIndex />}
      <MetricsAndTracking
        loadHotJar={loadHotJar}
        loadMaze={loadMaze}
        loadRedditPixel={true}
      />
      <TitleForHead title={title} />
      <div className={classMerge('min-h-[80vh]', className)}>
        {backgroundGradient}
        <ResponsivePageMargin contentWidth={contentWidth} unstyled={unstyled}>
          <div
            className={classMerge(
              'grid grid-cols-1',
              spacing ? sizeToSpacing[spacing] : 'gap-4',
            )}
          >
            {children}
          </div>
        </ResponsivePageMargin>
      </div>
    </>
  )
}

export type LayoutProps = {
  backgroundGradient?: JSX.Element
  canonicalUrl?: Url
  children?: JSX.Element | JSX.Element[]
  className?: string
  contentWidth?: PageWidth
  description?: string
  loadHotJar?: boolean
  loadMaze?: boolean
  noIndex?: boolean
  spacing?: Spacing
  title?: string
  unstyled?: boolean
}
