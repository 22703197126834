import type { CallType } from '@lib/types/CallType'
import absoluteUrl from './helpers/absoluteUrl'

// a function that has an optional object as a parameter that has a boolean property set to false as default
const getBaseApiUrl = (
  { forceProduction } = {
    forceProduction: false,
  },
) => absoluteUrl('/api', { forceProduction })

export const BaseApiUrl = getBaseApiUrl()

const VERSION_1 = 'v1'

export const MAGIC_PASSPHRASE_FOR_CACHE_INVALIDATION = 'REVALIDATE_ME_PLEASE' // FIXME: Be more secure about this

const ApiRoutes = {
  [VERSION_1]: {
    admin: {
      healthCheck: `${BaseApiUrl}/${VERSION_1}/admin/healthz`,
      sponsorMonthlyBrief: {
        getEmailProps: (nctId: string) =>
          `${BaseApiUrl}/${VERSION_1}/admin/sponsor-monthly-brief/email-data/${nctId}`,
      },
    },
    algolia: {
      search: `${BaseApiUrl}/${VERSION_1}/algolia/search`,
      searchForFacetValues: `${BaseApiUrl}/${VERSION_1}/algolia/search-for-facet-values`,
    },
    callbacks: {
      appcache: (path: string, forceProduction = { forceProduction: false }) =>
        `${getBaseApiUrl(forceProduction)}/${VERSION_1}/callbacks/appcache${path}?passphrase=${MAGIC_PASSPHRASE_FOR_CACHE_INVALIDATION}`,
      calendly: `${BaseApiUrl}/${VERSION_1}/callbacks/calendly`,
      conference: {
        recording: `${BaseApiUrl}/${VERSION_1}/callbacks/conference/recording`,
      },
      daily: `${BaseApiUrl}/${VERSION_1}/callbacks/daily`,
      geolocate: `${BaseApiUrl}/${VERSION_1}/callbacks/geolocate`,
      midjourney: `${BaseApiUrl}/${VERSION_1}/callbacks/midjourney`,
      pagecache: (path: string, forceProduction = { forceProduction: false }) =>
        `${getBaseApiUrl(forceProduction)}/${VERSION_1}/callbacks/pagecache${path}?passphrase=${MAGIC_PASSPHRASE_FOR_CACHE_INVALIDATION}`,
      phone: (type: CallType) =>
        `${BaseApiUrl}/${VERSION_1}/callbacks/phone/${type}`,
      pusherBeamsAuth: (userIdToken: string) =>
        `${BaseApiUrl}/${VERSION_1}/callbacks/pusher/beams-auth/${userIdToken}`,
      retell: `${BaseApiUrl}/${VERSION_1}/callbacks/retell`,
      sendbird: `${BaseApiUrl}/${VERSION_1}/callbacks/sendbird`,
      signwell: `${BaseApiUrl}/${VERSION_1}/callbacks/signwell`,
      typeform: `${BaseApiUrl}/${VERSION_1}/callbacks/typeform`,
      vcf: (trialApplyIdToken: string) =>
        `${BaseApiUrl}/${VERSION_1}/callbacks/vcf/${trialApplyIdToken}`,
    },
    eligibilityCriteria: {
      concept: {
        search: `${BaseApiUrl}/${VERSION_1}/eligibility-criteria/concept/search`,
      },
    },
    facilities: {
      search: `${BaseApiUrl}/${VERSION_1}/facilities/search`,
    },
    location: {
      autocomplete: `${BaseApiUrl}/${VERSION_1}/location/autocomplete`,
    },
    notifications: {
      subscribe: `${BaseApiUrl}/${VERSION_1}/notifications/subscribe`,
    },
    patients: {
      conditions: `${BaseApiUrl}/${VERSION_1}/patients/conditions`,
      emailSubscription: `${BaseApiUrl}/${VERSION_1}/patients/email/subscribe`,
      navigatorCalls: (callId: string) =>
        `${BaseApiUrl}/${VERSION_1}/patients/navigator-calls/${callId}`,
      recommendations: `${BaseApiUrl}/${VERSION_1}/patients/recommendations`,
      trials: {
        applyPackage: (idToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/patients/trials/apply-package/${idToken}`,
        consentForEmr: (patientTrialApplicationIdToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/patients/trials/consent-for-emr/${patientTrialApplicationIdToken}`,
        emailCheck: (slug: string) =>
          `${BaseApiUrl}/${VERSION_1}/patients/trials/email-check/${slug}`,
        medicalDocuments: (patientTrialApplicationIdToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/patients/trials/medical-documents/${patientTrialApplicationIdToken}`,
      },
      videoCall: {
        start: `${BaseApiUrl}/${VERSION_1}/patients/video-call/start`,
      },
    },
    productTours: {
      updateStatus: `${BaseApiUrl}/${VERSION_1}/product-tours/update-status`,
    },
    search: `${BaseApiUrl}/${VERSION_1}/search`,
    shortcode: {
      upsert: `${BaseApiUrl}/${VERSION_1}/shortcode/upsert`,
    },
    trialConsentForms: {
      upload: `${BaseApiUrl}/${VERSION_1}/trial-consent-forms/upload`,
    },
    trialLocations: {
      create: `${BaseApiUrl}/${VERSION_1}/trial-locations/create`,
    },
    trialPros: {
      clinicalOrganization: {
        create: `${BaseApiUrl}/${VERSION_1}/trial-pros/clinical-organization`,
        location: {
          create: `${BaseApiUrl}/${VERSION_1}/trial-pros/clinical-organization/location`,
          trial: {
            add: `${BaseApiUrl}/${VERSION_1}/trial-pros/clinical-organization/location/trial`,
          },
        },
        role: {
          create: `${BaseApiUrl}/${VERSION_1}/trial-pros/clinical-organization/role`,
        },
      },
      edit: `${BaseApiUrl}/${VERSION_1}/trial-pros/edit`,
      eligibilityCriterion: {
        create: `${BaseApiUrl}/${VERSION_1}/trial-pros/trial/eligibility-criterion`,
        delete: (id: number) =>
          `${BaseApiUrl}/${VERSION_1}/trial-pros/trial/eligibility-criterion/${id}`,
        update: (id: number) =>
          `${BaseApiUrl}/${VERSION_1}/trial-pros/trial/eligibility-criterion/${id}`,
      },
      emrs: {
        request: `${BaseApiUrl}/${VERSION_1}/trial-pros/emrs/request`,
      },
      fax: {
        patientConsentToDoctor: `${BaseApiUrl}/${VERSION_1}/trial-pros/fax/patientConsentToDoctor`,
      },
      invite: `${BaseApiUrl}/${VERSION_1}/trial-pros/invite`,
      managedTrial: {
        claim: `${BaseApiUrl}/${VERSION_1}/trial-pros/managed-trial/claim`,
      },
      multiClaim: `${BaseApiUrl}/${VERSION_1}/trial-pros/multi-claim`,
      patientTrialApplication: {
        applyPackage: (patientTrialApplicationIdToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/apply-package/${patientTrialApplicationIdToken}`,
        blockPatient: (patientTrialApplicationIdToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/block-patient/${patientTrialApplicationIdToken}`,
        consentForEmr: (patientTrialApplicationIdToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/consent-for-emr/${patientTrialApplicationIdToken}`,
        documents: (idToken: string) =>
          `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/documents/${idToken}`,
        eligibilityResponse: {
          create: `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/eligibility-response/create`,
          edit: `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/eligibility-response/edit`,
        },
        export: `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/export`,
        sponsorApproval: `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/sponsor-approval`,
        update: `${BaseApiUrl}/${VERSION_1}/trial-pros/patient-trial-application/update`,
      },
      tasks: {
        assign: `${BaseApiUrl}/${VERSION_1}/trial-pros/tasks/assign`,
        complete: `${BaseApiUrl}/${VERSION_1}/trial-pros/tasks/complete`,
      },
      videoCall: {
        end: `${BaseApiUrl}/${VERSION_1}/trial-pros/video-call/end`,
        start: `${BaseApiUrl}/${VERSION_1}/trial-pros/video-call/start`,
      },
    },
    trials: {
      completionAttributes: (nctId: string) =>
        `${BaseApiUrl}/${VERSION_1}/trials/completion-attributes/${nctId}`,
      locations: {
        near: {
          latlng: (
            slug: string,
            latlng: { latitude: number; longitude: number },
          ) =>
            `${BaseApiUrl}/${VERSION_1}/trials/${slug}/locations/near/latlng/${latlng.latitude}/${latlng.longitude}`,
          zip: (slug: string, zipcode: string) =>
            `${BaseApiUrl}/${VERSION_1}/trials/${slug}/locations/near/zip/${zipcode}`,
        },
      },
    },
    users: {
      create: `${BaseApiUrl}/${VERSION_1}/users/create`,
      inbox: {
        createChannel: `${BaseApiUrl}/${VERSION_1}/users/inbox/create-channel`,
        unreadMessageCount: `${BaseApiUrl}/${VERSION_1}/users/inbox/unread-message-count`,
      },
      unsubscribeFromEmail: `${BaseApiUrl}/${VERSION_1}/users/email/unsubscribe`,
    },
  },
}

export default ApiRoutes
