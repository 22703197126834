import { isProcessClientSide } from '@lib/config/runtime'

/* Reference: https://github.com/f2etw/detect-inapp/issues/52#issuecomment-1926586689 */
/* set of Regex to compare against user agent in order to detect facebook's in-app browser */
const InAppBrowserRegex = [
  /Instagram/,
  /FB_IAB/,
  /FBAN/,
  /FBIOS/,
  /FBAV/,
  /FBBV/,
  /FBDV/,
  /FBMD/,
  /FBSN/,
  /FBSV/,
  /FBSS/,
  /FBID/,
  /FBLC/,
  /FBOP/,
  /FBRV/,
]

export function isInAppBrowser(): boolean {
  if (!isProcessClientSide()) return false

  // Extra check for safety
  try {
    if (typeof navigator === 'undefined' || !navigator.userAgent) return false
    return InAppBrowserRegex.some((value) => value.test(navigator.userAgent))
  } catch (e) {
    return false
  }
}

/* set of Regex to compare against user agent in order to detect iOS or Android */
const iosRegex = /(iPhone|iPod|iPad)/ // iOS
const androidRegex = /Android/ // Android

export function isIos(userAgent: string): boolean {
  return iosRegex.test(userAgent)
}

export function isAndroid(userAgent: string): boolean {
  return androidRegex.test(userAgent)
}
