export const hoverStates = {
  baby: {
    accent: 'hover:bg-baby200 active:bg-baby300',
    filled: 'hover:bg-baby300 active:bg-baby400',
    neutral: 'hover:bg-baby100 active:bg-baby200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  black: {
    accent: 'hover:bg-neutral300 active:bg-neutral400',
    filled: 'hover:bg-neutral800 active:bg-neutral700',
    neutral: 'hover:bg-neutral100 active:bg-neutral200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  blue: {
    accent: 'hover:bg-blue200 active:bg-blue300',
    filled: 'hover:bg-blue300 active:bg-blue400',
    neutral: 'hover:bg-blue100 active:bg-blue200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  darkGrey: {
    accent: 'hover:bg-neutral200 active:bg-neutral300',
    filled: 'hover:bg-neutral200 active:bg-neutral300',
    neutral: 'hover:bg-neutral100 active:bg-neutral200',
    outlined: 'hover:bg-neutral100 active:bg-neutral200',
  },
  destructive: {
    accent: 'hover:bg-destructive200 active:bg-destructive300',
    filled: 'hover:bg-destructive300 active:bg-destructive400',
    neutral: 'hover:bg-destructive100 active:bg-destructive200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  green: {
    accent: 'hover:bg-green200 active:bg-green300',
    filled: 'hover:bg-green300 active:bg-green400',
    neutral: 'hover:bg-green100 active:bg-green200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  hope: {
    accent: 'hover:bg-hope200 active:bg-hope300',
    filled: 'hover:bg-hope300 active:bg-hope400',
    neutral: 'hover:bg-hope100 active:bg-hope200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  lightGrey: {
    accent: 'hover:bg-neutral200 active:bg-neutral300',
    filled: 'hover:bg-neutral200 active:bg-neutral300',
    neutral: 'hover:bg-neutral100 active:bg-neutral200',
    outlined: 'hover:bg-neutral100 active:bg-neutral200',
  },
  mediumGrey: {
    accent: 'hover:bg-neutral200 active:bg-neutral300',
    filled: 'hover:bg-neutral200 active:bg-neutral300',
    neutral: 'hover:bg-neutral100 active:bg-neutral200',
    outlined: 'hover:bg-neutral100 active:bg-neutral200',
  },
  neutral: {
    accent: 'hover:bg-neutral200 active:bg-neutral300',
    filled: 'hover:bg-neutral200 active:bg-neutral300',
    neutral: 'hover:bg-neutral100 active:bg-neutral200',
    outlined: 'hover:bg-neutral100 active:bg-neutral200',
  },
  primary: {
    accent: 'hover:bg-primary200 active:bg-primary300',
    filled: 'hover:bg-primary300 active:bg-primary400',
    neutral: 'hover:bg-primary100 active:bg-primary200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  seaGreen: {
    accent: 'hover:bg-seaGreen200 active:bg-seaGreen300',
    filled: 'hover:bg-seaGreen300 active:bg-seaGreen400',
    neutral: 'hover:bg-seaGreen100 active:bg-seaGreen200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  success: {
    accent: 'hover:bg-success200 active:bg-success300',
    filled: 'hover:bg-success400 active:bg-success500',
    neutral: 'hover:bg-success100 active:bg-success200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  transparent: {
    accent:
      'hover:bg-neutral200 hover:bg-opacity-25 active:bg-neutral300 active:bg-opacity-25',
    filled:
      'text-white hover:bg-neutral200 hover:bg-opacity-25 active:bg-neutral300 active:bg-opacity-25',
    neutral:
      'hover:bg-neutral200 hover:bg-opacity-25 active:bg-neutral300 active:bg-opacity-25',
    outlined:
      'bg-transparent neutral200 hover:bg-opacity-25 active:bg-neutral300 active:bg-opacity-25',
  },
  turquoise: {
    accent: 'hover:bg-turquoise200 active:bg-turquoise300',
    filled: 'hover:bg-turquoise300 active:bg-turquoise400',
    neutral: 'hover:bg-turquoise100 active:bg-turquoise200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  warm: {
    accent: 'hover:bg-warm200 active:bg-warm300',
    filled: 'hover:bg-warm300 active:bg-warm400',
    neutral: 'hover:bg-warm100 active:bg-warm200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  warning: {
    accent: 'hover:bg-warning200 active:bg-warning300',
    filled: 'hover:bg-warning300 active:bg-warning400',
    neutral: 'hover:bg-warning100 active:bg-warning200',
    outlined: 'hover:bg-neutral200 active:bg-neutral300',
  },
  white: {
    accent: 'hover:bg-neutral100 active:bg-neutral200',
    filled: 'hover:bg-neutral100 active:bg-neutral200',
    neutral: 'hover:bg-neutral100 active:bg-neutral200',
    outlined: 'hover:bg-neutral100 active:bg-neutral200',
  },
}
