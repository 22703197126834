import type { ColorGroup, ColorValue } from '@lib/colors/colors'
import { colorClasses } from './colorClasses'
import { hoverStates } from './hoverStates'

export type ColorGroupVariant = 'filled' | 'accent' | 'outlined' | 'neutral'

// TODO - we will probably want to add more logic for a11y here
export const getColorVariant = (
  colorCategory: ColorGroup,
  variant: ColorGroupVariant,
): string => {
  return colorClasses[colorCategory][variant]
}

export const getColorHoverStates = (
  colorCategory: ColorGroup,
  variant: ColorGroupVariant,
): string => {
  return hoverStates[colorCategory][variant]
}

/**
 * Converts a CSS hexadecimal color string to a string representing the same hexadecimal number.
 *
 * @param {ColorValue} cssHexColor - The CSS hexadecimal color string (e.g., `#FFFFFF`) that needs to be converted.
 * @returns {string} The converted color string with the `#` replaced by `0x` (e.g., `0xFFFFFF`).
 */
export const convertColorToHexString = (cssHexColor: ColorValue): string => {
  return cssHexColor.replace('#', '0x')
}
