export const ONE_SECOND_IN_MS = 1000
export const FIFTEEN_SECONDS_IN_MS = ONE_SECOND_IN_MS * 15
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60
export const FIVE_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 5
export const TEN_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 10
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60
export const ONE_HOUR_IN_SECONDS = 60 * 60
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24
export const ONE_DAY_IN_MS = 1000 * ONE_DAY_IN_SECONDS
export const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7
export const ONE_WEEK_IN_MS = ONE_DAY_IN_MS * 7

export const TWO_DAYS_IN_SECONDS = ONE_DAY_IN_SECONDS * 2
export const TWO_DAYS_IN_MS = 1000 * TWO_DAYS_IN_SECONDS

export const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60
export const THIRTY_DAYS_IN_MS = THIRTY_DAYS_IN_SECONDS * ONE_SECOND_IN_MS

export const FOUR_DAYS_IN_MS = ONE_DAY_IN_MS * 4
export const THREE_WEEKS_IN_MS = ONE_WEEK_IN_MS * 3
export const SIX_WEEKS_IN_MS = ONE_WEEK_IN_MS * 6
